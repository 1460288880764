<template>
  <div class="content dash-con">
    <div class="md-layout" v-if="hasPermission">
      <!-- Product Sales Report-->
      <ProductReport :data="productData" :loading="p_loading" />
      <!-- Order Status report -->
      <OrderReport :data="orderData" :loading="o_loading" />

      <!-- Professional report -->
      <ProfessionalReport :data="userData" :loading="u_loading" />

      <!-- Handyman report -->
      <HandymanReport :data="userData" :loading="u_loading" />

      <!-- User Status report -->
      <UserReport :data="userData" :loading="u_loading" />
      <!-- Notifications report -->
      <Notifications :data="notifications" :loading="n_loading" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapActions, mapGetters } = createNamespacedHelpers("dataStore");

import {
  OrderReport,
  ProductReport,
  UserReport,
  Notifications,
  ProfessionalReport,
  HandymanReport,
} from "@/components";

export default {
  components: {
    OrderReport,
    ProductReport,
    UserReport,
    Notifications,
    ProfessionalReport,
    HandymanReport,
  },
  data() {
    return {
      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },

        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De",
          ],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
    };
  },
  methods: {
    ...mapActions([
      "getProductData",
      "getOrderData",
      "getUserData",
      "getNotifications",
    ]),
  },
  mounted() {
    this.getProductData();
    this.getOrderData();
    this.getUserData();
    this.getNotifications(5);
  },
  computed: {
    ...mapGetters([
      "loading",
      "p_loading",
      "o_loading",
      "u_loading",
      "n_loading",
      "productData",
      "orderData",
      "userData",
      "notifications",
    ]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("all");
    },
  },
};
</script>

<style>
.dash-con {
  padding: 30px 30px !important;
}

@media (max-width: 640px) {
  .dash-con {
    padding: 30px 0px 30px 17px !important;
  }
}
</style>
